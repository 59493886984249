class FooterSectionComponent extends footerSectionComponent {

    getTemplate() {
        return `<footer class="container-fluid pt-4 pb-2" v-if="mustRender">
                    <div class="container">
                        <div class="row py-5">
                            <div class="col-12 col-md-auto col-lg-8 text-center text-md-left mt-0 mt-md-3 footer-links">
                                <div class="row">
                                    <div class="col-12 col-md-auto">
                                        <img src="/static/ecommerce/img/break_saludable_footer.png">
                                    </div>
                                    <div class="col">
                                        <router-link class="main-link" to="/about">Sobre nosotros</router-link>
                                        <router-link to="/about#historia">Historia</router-link>
                                        <router-link to="/about#mision">Misión</router-link>
                                        <router-link to="/about#proposito">Propósito</router-link>
                                    </div>
                                    <div class="col">
                                        <a class="main-link no-link">Concepto</a>
                                        <router-link to="/homeoffice">Home Office</router-link>
                                        <router-link to="/comunidad">Colaboradores</router-link>
                                        <router-link to="/incompany">In Company</router-link>
                                    </div>
                                    <div class="col">
                                        <router-link class="main-link" to="/contact">Contactanos</router-link>
                                        <router-link to="/contact#sugerencias">Hacenos sugerencias</router-link>
                                        <router-link to="/contact#formaparte">Formá parte del equipo Ofisnack</router-link>
                                        <router-link to="/contact#proveedor">Quiero ser proveedor</router-link>
                                        <router-link to="/contact#reclamo">Envianos tu reclamo</router-link>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <img class="responsive-img" src="./../assets/safe_site">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 text-center text-lg-left mt-5 mt-lg-0 footer-social">
                                <p class="title m-0">Más OfiSnack</p>
                                <p class="mail">comunidad@ofisnack.com</p>
                                <a v-if="enableFB" :href="linkFB" target="_blank"><span class="social-icon"><i class="icon fab fa-facebook-f"></i></span></a>
                                <a v-if="enableIG" :href="linkIG" target="_blank"><span class="social-icon"><i class="icon fab fa-instagram"></i></span></a>
                                <div class="payment mt-4">
                                    <p class="mb-1 d-inline-block">Medios de pago</p>
                                    <img src="/static/ecommerce/img/mercado-pago-iso.png">
                                    <div class="tarjetas-credito">
                                        <p class="mb-0">Tarjeta de crédito en hasta 12 cuotas</p>
                                        <ul>
                                            <li class="paymentmethod visa">Visa</li>
                                            <li class="paymentmethod master">Mastercard</li>
                                            <li class="paymentmethod amex">American Express</li>
                                            <li class="paymentmethod mercadopago_cc">Mercado Pago + Banco Patagonia</li>
                                            <li class="paymentmethod naranja">Naranja</li>
                                            <li class="paymentmethod tarshop">Tarjeta Shopping</li>
                                            <li class="paymentmethod nativa">Nativa</li>
                                            <li class="paymentmethod cencosud">Cencosud</li>
                                            <li class="paymentmethod cabal">Cabal</li>
                                            <li class="paymentmethod argencard">Argencard</li>
                                            <li class="paymentmethod diners">Diners</li>
                                            <li class="paymentmethod cordobesa">Cordobesa</li>
                                            <li class="paymentmethod cmr">CMR</li>
                                            <li class="paymentmethod cordial">Cordial</li>
                                        </ul>
                                    </div>
                                    <div class="tarjetas-debito mt-2">
                                        <p class="mb-0">Tarjeta de débito</p>
                                        <ul>
                                            <li class="paymentmethod visadeb">Visa Débito</li>
                                            <li class="paymentmethod masterdeb">Mastercard Débito</li>
                                            <li class="paymentmethod maestro">Maestro</li>
                                            <li class="paymentmethod cabaldeb">Cabal Débito</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-1 text-center mt-5 mt-lg-0">
                                <a href="//qr.afip.gob.ar/?qr=JAWopgFbswaOWCaryUkZZg,," target="_F960AFIPInfo">
                                    <img src="//www.afip.gob.ar/images/f960/DATAWEB.jpg" class="img-fluid" style="max-width: 80px;" >
                                </a>
                            </div>
                        </div>
                        
                        <div class="row justify-content-center py-3 copyright-footer">
                            <div class="col-12 col-md-6 text-center text-md-left">
                                Copyright © {{year}} <a href="#" class="brand">{{menuTitle}}</a> All rights reserved.
                            </div>
                            <div class="col-12 col-md-6 text-center text-md-right mt-2 mt-md-0">
                                <span class="right">with <i class="icon fas fa-heart"></i> by <a href="https://oppen.io/" target="_blank" class="brand">öppen</a></span>
                            </div>
                        </div>
                    </div>
                </footer>`;
    }
}

FooterSectionComponent.registerComponent();
